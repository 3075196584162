import type { Answer } from "../types/answerer"

// /group/{groupOid}/static_card_contents/{staticCardContentOid}
export const STATIC_CARD_CONTENT_COLLECTION = "static_card_contents"

interface ZoomPrivateChatResultSink {
  kind: "ZOOM_CHAT"
}

type AssistedCallResultSinkType = ZoomPrivateChatResultSink

interface LiveAssistedCallBase {
  oid: string

  start_time: FirebaseFirestore.Timestamp
  end_time: FirebaseFirestore.Timestamp | null

  context_for_prompt: string
  boost_words: string[] | null

  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  creator_uid: string
  creator_email: string
  group_oid: string
}

export interface LiveAssistedCallWeb extends LiveAssistedCallBase {
  meeting_type: "WEB"
}

export interface LiveAssistedCallSimulation extends LiveAssistedCallBase {
  meeting_type: "SIMULATION"
  simulation_input_location: string
}

interface LiveAssistedCallZoom extends LiveAssistedCallBase {
  meeting_type: "ZOOM"
  meeting_id: string
  join_url: string
  result_sink_types: AssistedCallResultSinkType[]
  meeting_system_creator_user_id: string
  bot_state: "START" | "ACTIVE" | "DEACTIVATING" | "DEACTIVATED"
}

export type LiveAssistedCall =
  | LiveAssistedCallZoom
  | LiveAssistedCallWeb
  | LiveAssistedCallSimulation

export type LiveAssistedCallCreationRequest =
  | {
      meeting_type: "ZOOM"
      join_url: string
      start_time: FirebaseFirestore.Timestamp | null
      context_for_prompt: string
      boost_words: string[] | null
      message_recipient_display_name: string | null
    }
  | {
      meeting_type: "WEB"
      context_for_prompt: string
      boost_words: string[] | null
    }

interface LiveSimulationConfig {
  kinds: string[]
}

export interface LiveSessionConfig {
  disabled_actors: string[]
  simulation?: LiveSimulationConfig
  use_live_assistant_mini?: boolean
  model_name?: string
}

export interface LiveTranscriptSegment {
  oid: string
  created_at: FirebaseFirestore.Timestamp
  speaker_id: string
  speaker_name?: string
  text: string
  start_time: number
  end_time: number
}

interface BaseLiveEvent {
  oid: string
  kind: string
  created_at: FirebaseFirestore.Timestamp
}

interface LiveUIMeetingStartedEvent extends BaseLiveEvent {
  kind: "MEETING_STARTED"
}

interface LiveUIQuestionDetectedEvent extends BaseLiveEvent {
  kind: "QUESTION_FOUND"
  question_id: string
  question: string
}

interface LiveUIQuestionAnsweredEvent extends BaseLiveEvent {
  kind: "QUESTION_ANSWERED"
  question_id: string
  question: string
  answer: Answer
}

interface LiveUIQuestionNotAnsweredEvent extends BaseLiveEvent {
  kind: "QUESTION_NOT_ANSWERED"
  question_id: string
  question: string
}

export interface LiveUITranscriptEvent extends BaseLiveEvent {
  kind: "TRANSCRIPT_SEGMENT_UPDATE"
  segment: LiveTranscriptSegment
}

export interface LiveUICardUpdateEvent extends BaseLiveEvent {
  kind: "LIVE_UI_CARD_UPDATE"
  card: LiveUICard
}

export interface TranscriptSearchRequest extends BaseLiveEvent {
  kind: "TRANSCRIPT_SEARCH_REQUEST"
  query: string
}

export interface TranscriptSearchResponse extends BaseLiveEvent {
  kind: "TRANSCRIPT_SEARCH_RESPONSE"
  results: {
    context: string
    answer: string
    segment_oid: string | null
  }[]
}

interface UserActor {
  uid: string
  email: string
}

export interface StaticCardContent {
  oid: string

  created_at: FirebaseFirestore.Timestamp
  updated_at: FirebaseFirestore.Timestamp

  condition_prompt: string
  content: string
  mimetype: "text/markdown"

  created_by: UserActor
  last_updated_by: UserActor
}

export type LiveUIEvent =
  | LiveUITranscriptEvent
  | LiveUIMeetingStartedEvent
  | LiveUIQuestionDetectedEvent
  | LiveUIQuestionAnsweredEvent
  | LiveUIQuestionNotAnsweredEvent
  | TranscriptSearchResponse
  | LiveUICardUpdateEvent

export type LiveInputEvent = AudioDataEvent

interface LiveUICardBase {
  oid: string
  kind: string
  created_at: FirebaseFirestore.Timestamp

  dismissed: boolean
  seen: boolean
  pinned?: boolean
}

export interface AnsweredQuestionUICard extends LiveUICardBase {
  kind: "ANSWERED_QUESTION"
  question: string

  answer_state: "PENDING" | "ERROR" | "DONE"
  answer?: Answer | null

  error_message?: string | null
}

export interface AudioDataEvent extends BaseLiveEvent {
  kind: "AUDIO_DATA"
  sample_rate: number
  channels: number
  node_id: number | string
  speaker_display_name: string | null
  meeting_id: string | null
  speech_time: Date
  timestamp_ms: number

  packed_data: string
}

export interface AutoSearchUICard extends LiveUICardBase {
  kind: "AUTO_SEARCH"

  query: string
  context: string

  answer_state: "PENDING" | "ERROR" | "DONE"
  results:
    | null
    | {
        title: string
        link: string
        snippet: string
      }[]

  error_message?: string | null
}

export interface StaticContentUICard extends LiveUICardBase {
  kind: "STATIC_CONTENT"

  matched_text?: string
  body: StaticCardContent
}

export type LiveUICard =
  | AnsweredQuestionUICard
  | AutoSearchUICard
  | StaticContentUICard

export type CardDetail = AnsweredQuestionUICard
