import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import type { User as AuthUser } from "firebase/auth"
import { useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import liveImgSrc from "../../assets/img/live.jpg"
import Header from "../../components/Header"
import useErrorPopup from "../../hooks/useErrorPopup"
import LiveAssistedCallsList from "../../live/LiveAssistedCallsList"
import { createLiveAssistedCall } from "../../live/api"

interface FormData {
  contextForPrompt: string
  boostWords: string
}

const StartWebLiveAssistantForm: React.FC = () => {
  const [form] = Form.useForm<FormData>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { handleSuccess, handleError, messageApi } = useErrorPopup()
  const navigate = useNavigate()

  const onFinish = useCallback(
    async (formData: FormData) => {
      try {
        if (submitting) {
          return
        }

        setSubmitting(true)
        void messageApi.open({ type: "info", content: "Creating assistant..." })
        const liveAssistedCall = await createLiveAssistedCall({
          meeting_type: "WEB",
          context_for_prompt: formData.contextForPrompt,
          boost_words: (formData.boostWords || "")
            .split(",")
            .map((w) => w.trim())
            .filter(Boolean),
        })
        console.log("Created call", liveAssistedCall)
        handleSuccess("Created! Click Play to start the call.")
        navigate(`/live-assistant/${liveAssistedCall.oid}`)
      } catch (error) {
        handleError({ error, prefix: "Could not create assistant" })
      } finally {
        setSubmitting(false)
      }
    },
    [handleSuccess, handleError, messageApi, navigate, submitting],
  )

  const initialValues: FormData = {
    contextForPrompt: "",
    boostWords: "",
  }

  const formUI = (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      className="mb-12 max-w-[800px]"
    >
      <Form.Item name="boostWords" label="Words to boost">
        <Input
          disabled={submitting}
          title="Words to boost (use comma to separate)"
          placeholder="Quilt, SOC-2"
        />
      </Form.Item>
      <Form.Item
        name="contextForPrompt"
        label="Context for prompt"
        rules={[{ message: "Please input context for prompt" }]}
      >
        <Input.TextArea
          disabled={submitting}
          title="Context for prompt"
          placeholder="Quilt is a secure platform for data sharing. This call is about..."
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          className="w-26"
          disabled={submitting}
          htmlType="submit"
        >
          {submitting ? "Creating..." : "Create Call"}
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <>
      <div className="mb-8 flex w-full max-w-[600px] items-center">
        <img
          className="h-auto w-[96px] rounded-3xl"
          src={liveImgSrc}
          alt="Live Assistant"
          width={1024}
          height={1024}
        />
        <div>
          <div className="ml-4 text-lg font-semibold">
            Try the new Quilt Live Assistant
          </div>
          <div className="text-md ml-4">
            The assistant will listen to your call and answer questions it
            hears.
          </div>
        </div>
      </div>
      {formUI}
    </>
  )
}

const WebLiveAssistantPage: React.FC<{ user: AuthUser }> = ({ user }) => {
  return (
    <>
      <Header title="Live Assistant">
        <Link to="/live-assistant/cards">
          <Button type="default">View/Edit Cards</Button>
        </Link>
      </Header>
      <div className="max-w-[1200px] p-4">
        <StartWebLiveAssistantForm />
        <LiveAssistedCallsList user={user} />
      </div>
    </>
  )
}

export default WebLiveAssistantPage
